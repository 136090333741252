'use client';
import { useThemeContext } from '@/features/theme/context';
import { ThemeMode } from '@/features/theme/hooks/useThemeMode';
import { ChangeEvent } from 'react';
import Select from '../../common/components/forms/Select';
import { SystemComputer, Sun, Moon } from '../../common/components/icons';

export default function DarkModeSelect({ className }: { className?: string }): JSX.Element {
	const [{ themeMode }, selectThemeMode] = useThemeContext();
	const handleThemeChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const mode: ThemeMode = e.target.value as unknown as ThemeMode;
		selectThemeMode(mode);
	};
	const themeOptions = [
		{ value: ThemeMode.SYSTEM, title: 'Système', label: 'Suivre les paramètres du système', icon: <SystemComputer /> },
		{ value: ThemeMode.DARK, title: 'Sombre', label: 'Activer le mode sombre', icon: <Sun /> },
		{ value: ThemeMode.LIGHT, title: 'Clair', label: 'Activer le mode clair', icon: <Moon /> },
	];

	return (
		<Select
			label='Change Color Scheme'
			id='theme-selector'
			options={themeOptions}
			selected={themeMode}
			onChange={handleThemeChange}
			className={className}
		/>
	);
}

'use client';
import Container from './Container';
import Link from 'next/link';
import Image from 'next/image';
import { Phone, MapPin, Facebook, Radio, YouTube } from '@/common/components/icons';
import { v4 as uuid } from 'uuid';
import { FOOTER_LINKS } from '../../data';
import Copyright from './Copyright';

export default function Footer({ className = '' }: { className?: string }): JSX.Element {
	const FOOTER_INFOS = [
		{
			text: <a href='tel:+596696514669'>+596 696 51 46 69 (Antenne)</a>,
			icon: <Phone />,
		},
		{
			text: <a href='tel:+596696512427'>+596 696 51 24 27 (Secrétariat)</a>,
			icon: <Phone />,
		},
		{
			text: (
				<a href='https://goo.gl/maps/8iqXayn6X22DAG2U6' target='_blank' rel='noreferrer'>
					Morne Pavillon, 97240, Le François
				</a>
			),
			icon: <MapPin />,
		},
		{
			text: '89.3Mhz',
			icon: <Radio />,
		},
	];

	return (
		<footer className={`mx-auto mt-10 w-full bg-[#F9F9F9] pt-8 pb-32 dark:bg-[#303540] dark:text-white ${className}`}>
			<Container>
				<div className='my-10 grid place-content-center items-center justify-center  gap-10 md:grid-cols-[2fr,4fr] md:text-left'>
					<div className=''>
						<div className='mb-4'>
							<Link href='/' passHref>
								<Image
									src='/logo.png'
									alt='logo'
									width={200}
									height={79.05}
									style={{
										maxWidth: '100%',
										height: 'auto',
									}}
								/>
							</Link>
						</div>
						<ul className='flex flex-col'>
							{FOOTER_INFOS.map((info) => (
								<li className='my-2 flex items-center text-left' key={uuid()}>
									<span className='mr-3'>{info.icon}</span>
									<span>{info.text}</span>
								</li>
							))}
						</ul>
					</div>
					<div className='grid grid-cols-2 gap-10 md:grid-cols-4'>
						{FOOTER_LINKS.map(({ sectionTitle, links }) => (
							<div key={sectionTitle} className='flex flex-col'>
								<h2 className='mb-2 text-xl font-bold'>{sectionTitle}</h2>
								<ul className='font-light'>
									{links.map(({ title, url }) => (
										<li key={title} className='my-2'>
											<Link href={url} passHref className='link'>
												{title}
											</Link>
										</li>
									))}
								</ul>
							</div>
						))}

						<div className='flex flex-col items-center justify-center gap-5 md:justify-start'>
							<a href='https://www.facebook.com/radiosudest/' className='link' target='_blank' rel='noreferrer'>
								<Facebook />
							</a>
							<a
								href='https://www.youtube.com/user/radiosudest972'
								className='link'
								target='_blank'
								rel='noreferrer'
							>
								<YouTube />
							</a>
						</div>
					</div>
				</div>
				<Copyright />
			</Container>
		</footer>
	);
}

'use client';
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { InformationI } from '@/common/components/icons';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';

export default function AdBox({
	client = 'ca-pub-4617620145917473',
	slot = '2742989197',
	className,
}: {
	client?: string;
	slot?: string;
	className?: string;
}): JSX.Element {
	const adRef = useRef<HTMLModElement>(null);
	const [adStatus, setAdStatus] = useState<string | null | undefined>('');

	useEffect(() => {
		try {
			(window as any).adsbygoogle = (window as any).adsbygoogle || [];
			(window as any).adsbygoogle.push({});
		} catch (e: any) {
			setAdStatus('unfilled');
			console.warn('Error while loading ad placement', e.message);
		}
	}, []);

	useEffect(() => {
		const adElement = adRef.current;

		const observer = new MutationObserver((mutationsList) => {
			for (const mutation of mutationsList) {
				if (mutation.type === 'attributes' && mutation.attributeName === 'data-ad-status') {
					const status = adElement?.getAttribute('data-ad-status');
					setAdStatus(status);
				}
			}
		});
		if (adElement) observer.observe(adElement, { attributes: true });

		return () => {
			observer.disconnect();
		};
	}, []);

	return (
		<aside className={`my-10 space-y-4 ${className} min-h-[120px]`}>
			<small className='mx-auto flex items-center justify-center gap-2 dark:text-gray-400'>
				<InformationI /> Publicité
			</small>
			{adStatus === 'unfilled' ? (
				<div className='mx-auto flex flex-col md:flex-row items-center justify-between gap-4 md:gap-10 md:w-11/12 rounded-lg border border-gray-300 bg-gradient-to-r from-[#bb5959] to-[#d98cdd] px-3  py-7  text-white dark:border-gray-600 dark:from-[#292929] dark:text-white md:items-center md:justify-between lg:w-9/12 h-full md:px-6 md:py-8'>
					<div className='flex flex-col text-center justify-center gap-4 md:gap-4 md:text-left'>
						<h2 className='text-lg font-bold lg:text-2xl'>Diffusez vos annonces</h2>
						<p className='text-md'>
							Mettez en avant vos produits, marques et services sur les plateformes de Radio Sud Est.
						</p>
					</div>
					<Link
						href='/contact'
						passHref
						className='block min-w-max rounded-md bg-white  from-[#ffffff] to-[#999999] px-3 py-3 text-sm text-black hover:bg-gradient-to-r'
					>
						Nous contacter
					</Link>
				</div>
			) : (
				// @ts-ignore
				<div align='center'>
					<ins
						ref={adRef}
						className='adsbygoogle'
						style={{ display: 'block' }}
						data-ad-client={client}
						data-ad-slot={slot}
						data-ad-format='auto'
						data-full-width-responsive='true'
						data-ad-status='unfilled'
					></ins>
				</div>
			)}
			{/* <script>(adsbygoogle = window.adsbygoogle || []).push({});</script> */}
			{/* <div className='mx-auto flex h-28 w-11/12 items-center justify-center rounded bg-gray-100 dark:bg-gray-800 md:w-8/12'> */}
		</aside>
	);
}

function AdReplacement() {
	return (
		<div className='mx-auto flex flex-col items-center justify-between gap-4 md:gap-10 md:w-11/12 rounded-lg border border-gray-300 bg-gradient-to-r from-[#bb5959] to-[#d98cdd] px-3  py-7  text-white dark:border-gray-600 dark:from-[#292929] dark:text-white md:items-center md:justify-center   md:flex-col h-full md:px-6 md:py-8'>
			<div className='flex flex-col text-center justify-center gap-4 md:gap-4 md:text-center'>
				<h2 className='text-lg font-bold lg:text-xl'>Diffusez vos annonces</h2>
				<p className='text-sm'>Mettez en avant vos produits, marques et services sur les plateformes de Radio Sud Est.</p>
			</div>
			<Link
				href='/contact'
				passHref
				className='block min-w-max rounded-md bg-white  from-[#ffffff] to-[#999999] px-3 py-3 text-sm text-black hover:bg-gradient-to-r'
			>
				Nous contacter
			</Link>
		</div>
	);
}
export function VerticalAdBox({
	client = 'ca-pub-4617620145917473',
	slot = '3875961677',
	className,
}: {
	client?: string;
	slot?: string;
	className?: string;
}): JSX.Element {
	const adRef = useRef<HTMLModElement>(null);
	const [adStatus, setAdStatus] = useState<string | null | undefined>('');

	useEffect(() => {
		try {
			(window as any).adsbygoogle = (window as any).adsbygoogle || [];
			(window as any).adsbygoogle.push({});
		} catch (e: any) {
			setAdStatus('unfilled');

			console.warn('Error while loading ad placement', e.message);
		}
	}, []);

	useEffect(() => {
		const adElement = adRef.current;

		const observer = new MutationObserver((mutationsList) => {
			for (const mutation of mutationsList) {
				if (mutation.type === 'attributes' && mutation.attributeName === 'data-ad-status') {
					const status = adElement?.getAttribute('data-ad-status');
					setAdStatus(status);
				}
			}
		});
		if (adElement) observer.observe(adElement, { attributes: true });

		return () => {
			observer.disconnect();
		};
	}, []);

	return (
		<aside className={`my-10 space-y-4 ${className} min-h-[120px]`}>
			<small className='mx-auto flex items-center justify-center gap-2 dark:text-gray-400'>
				<InformationI /> Publicité
			</small>

			{adStatus === 'unfilled' ? (
				<AdReplacement />
			) : (
				// @ts-ignore
				<div align='center'>
					<ins
						ref={adRef}
						className='adsbygoogle'
						style={{ display: 'block' }}
						data-ad-client={client}
						data-ad-slot={slot}
						data-ad-format='auto'
						data-full-width-responsive='true'
						data-ad-status='unfilled'
					></ins>
				</div>
			)}
			{/* <script>(adsbygoogle = window.adsbygoogle || []).push({});</script> */}
			{/* <div className='mx-auto flex h-28 w-11/12 items-center justify-center rounded bg-gray-100 dark:bg-gray-800 md:w-8/12'> */}
		</aside>
	);
}

export function PollAd() {
	return (
		<div className='mx-auto flex w-11/12  flex-col items-center justify-between gap-10 rounded-xl border border-gray-300 bg-gradient-to-r from-[#efefef] to-[#d98cdd] p-4  py-5  text-black dark:border-gray-600 dark:from-[#292929] dark:text-white md:w-8/12  md:flex-row md:px-6'>
			<div className='flex flex-col justify-center gap-3 text-center  md:gap-2 md:text-left'>
				<h2 className='text-xl font-bold lg:text-3xl'>Votre avis nous intéresse !</h2>
				<p className='text-sm'>Aidez-nous à améliorer votre expérience sur le site et vos interactions avec la radio.</p>
			</div>
			<Link
				href='/sondage'
				passHref
				className='block min-w-max rounded-md bg-white  from-[#ffffff] to-[#999999] px-3 py-3 text-sm text-black hover:bg-gradient-to-r'
			>
				Je participe
			</Link>
		</div>
	);
}

export function MultiplexAdBox({
	client = 'ca-pub-4617620145917473',
	slot = '9506728158',
	className,
}: {
	client?: string;
	slot?: string;
	className?: string;
}): JSX.Element {
	useEffect(() => {
		try {
			(window as any).adsbygoogle = (window as any).adsbygoogle || [];
			(window as any).adsbygoogle.push({});
		} catch (e: any) {
			console.warn('Error while loading ad placement', e.message);
		}
	}, []);
	return (
		<aside className={`my-10 space-y-2 ${className} min-h-[120px]`}>
			<small className='mx-auto flex items-center justify-center gap-2 dark:text-gray-400'>
				<InformationI /> Publicité
			</small>
			<ins
				className='adsbygoogle bg-red-300 border-darkGrey'
				style={{ display: 'block' }}
				data-ad-client={client}
				data-ad-slot={slot}
				data-full-width-responsive='true'
				data-ad-format='autorelaxed'
			></ins>
		</aside>
	);
}

import { classNames } from '@/common/helpers/styles';
import AdBox from '@/features/ads/AdBox';
import { IPage } from '@/models/page';
import { NextSeo } from 'next-seo';
import Container from '../components/layout/Container';
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';
import BasePage from './Base';

export default function Page({ children, className = '', seo, withAdBox = false, withPadding = true }: IPage): JSX.Element {
	const pageTitle = seo.slogan ? `${seo.title} — ${seo.slogan}` : `${seo.title} — Radio Sud Est, Martinique`;
	const pageDescription =
		seo.description ||
		`Écoutez en direct Radio Sud Est, la 1ère Radio sur le Kompas en Martinique pour découvrir les meilleures nouveautés de la Caraïbe, et plus encore !`;

	return (
		<BasePage seo={seo}>
			{/* <AdminBar /> */}
			<Header />
			<NextSeo
				title={pageTitle}
				description={pageDescription}
				canonical={seo.canonical}
				noindex={seo.noindex}
				nofollow={seo.nofollow}
				openGraph={{
					title: pageTitle,
					description: pageDescription,
					images: [
						{
							url: seo.image || 'https://assets.radiosudest.com/assets/radiosudest-og_wixuis.jpg',
							width: 800,
							height: 600,
							alt: seo.title || 'Radio Sud Est',
							type: 'image/jpeg',
						},
					],
					type: seo.type || 'website',
					article: {
						authors: seo.authors,
						publishedTime: seo.publishedTime,
						modifiedTime: seo.modifiedTime,
					},
				}}
				twitter={{
					cardType: 'summary_large_image',
				}}
			/>
			{withAdBox && (
				<Container className={className}>
					<AdBox />
				</Container>
			)}
			<Container className={classNames(className, withPadding ? 'pt-8' : '')}>{children}</Container>
			<Footer className='isolate mt-auto' />
		</BasePage>
	);
}

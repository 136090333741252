import ThemeSwitcher from '@/common/components/DarkModeSelect';
import Link from 'next/link';

export default function Copyright(): JSX.Element {
	return (
		<div className='flex flex-col justify-between gap-10 text-center md:flex-row md:text-left'>
			<div>
				<p>
					© 2022,{' '}
					<Link href='https://radiosudest.com' passHref className='underline'>
						Radio Sud Est
					</Link>{' '}
					par{' '}
					<a href='https://mathcrln.com' target='_blank' rel='noreferrer' className='link'>
						Mathieu Céraline
					</a>
				</p>
				<p>
					<Link href='/legal' passHref className='link'>
						Mentions légales
					</Link>
				</p>
			</div>

			<div className='mx-auto max-w-max md:mr-0'>
				<ThemeSwitcher />
			</div>
		</div>
	);
}

const NAV_LINKS = [
	{ href: '/', label: 'Accueil' },
	// { href: '/programmes', label: 'Programmes' },
	// { href: '/equipe', label: 'Équipe' },
	{ href: '/communiques', label: 'Communiqués' },
	{ href: '/podcasts', label: 'Podcasts' },
	{ href: '/contact', label: 'Contact' },
];

const FOOTER_LINKS = [
	{
		sectionTitle: 'Découvrez',
		links: [
			{ title: 'La Radio', url: '/radio' },
			{ title: 'Nos programmes', url: '/programmes' },
			{ title: 'Notre équipe', url: '/equipe' },
			{ title: "L'histoire de la radio", url: '/histoire' },
			{ title: "L'histoire du Kompa", url: '/histoire-kompa' },
		],
	},
	{
		sectionTitle: 'Collaborez',
		links: [
			{ title: 'Partenaires', url: '/bientot' },
			{ title: 'Annonceurs', url: '/bientot' },
			{ title: 'Communiqués', url: '/communiques' },
			{ title: 'Carrières', url: '/bientot' },
		],
	},
	{
		sectionTitle: 'Participez',
		links: [
			{ title: 'Petites Annonces', url: '/bientot' },
			{ title: 'Anniversaires', url: '/bientot' },
			{ title: 'Dedicaces', url: '/bientot' },
			{ title: 'Nous contacter', url: '/contact' },
		],
	},
];

export { NAV_LINKS, FOOTER_LINKS };

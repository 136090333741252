/* eslint-disable @next/next/no-img-element */
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { getProviders, getSession, signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import Page from '@/layout/Page';

export default function SignIn(): JSX.Element {
	const router = useRouter();
	const redirectedTo = router.query.redirect as string;

	async function handleGoogleSignin() {
		signIn('google', { callbackUrl: redirectedTo ? redirectedTo : '/admin' });
	}

	return (
		<Page
			seo={{
				title: 'Se connecter',
				description: 'Connectez-vous au site de Radio Sud Est',
				nofollow: true,
				noindex: true,
			}}
			className='text-white'
		>
			<div className='flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8'>
				<div className='sm:mx-auto sm:w-full sm:max-w-md'>
					<img className='mx-auto h-12 w-auto' src='/logo.png' alt='Radio Sud Est' />
					<h2 className='mt-6 text-center text-3xl font-bold tracking-tight'>Se connecter</h2>
					<p className='mt-2 text-center text-sm text-gray-600 dark:text-gray-400'>
						Connectez-vous pour intéragir avec Radio Sud Est.
					</p>
				</div>
				<div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
					<div className='bg-white py-8 px-4 shadow dark:bg-[#1d2228] sm:rounded-lg sm:px-10'>
						<form className='space-y-6' action='#' method='POST'>
							<div>
								<label htmlFor='email' className='block text-sm font-medium text-gray-700 dark:text-gray-100'>
									Adresse E-mail
								</label>
								<div className='mt-1'>
									<input
										id='email'
										name='email'
										type='email'
										autoComplete='email'
										placeholder='monemail@gmail.com'
										required
										disabled={true}
										className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
									/>
								</div>
							</div>

							<div>
								<label htmlFor='password' className='block text-sm font-medium text-gray-700 dark:text-gray-100'>
									Mot de passe
								</label>
								<div className='mt-1'>
									<input
										id='password'
										name='password'
										type='password'
										placeholder='********'
										autoComplete='current-password'
										required
										disabled={true}
										className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
									/>
								</div>
							</div>

							<div className='flex items-center justify-between'>
								<div className='flex items-center'>
									<input
										id='remember-me'
										name='remember-me'
										type='checkbox'
										disabled={true}
										className='h-4 w-4 rounded border-gray-300 text-primary-light focus:ring-indigo-500 dark:text-primary-dark'
									/>
									<label htmlFor='remember-me' className='ml-2 block text-sm text-gray-900 dark:text-gray-100'>
										Se souvenir de moi
									</label>
								</div>

								<div className='text-sm'>
									<a
										href='#'
										className='font-medium text-primary-light hover:text-indigo-500 dark:text-[#d98cdd]'
									>
										Mot de passe oublié ?
									</a>
								</div>
							</div>

							<div>
								<button
									type='submit'
									disabled={true}
									className='flex w-full justify-center rounded-md border border-transparent bg-primary-light py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-[#9C0AA3]'
								>
									Se connecter
								</button>
							</div>
						</form>

						<div className='mt-6'>
							<div className='relative'>
								<div className='absolute inset-0 flex items-center'>
									<div className='w-full border-t border-gray-300' />
								</div>
								<div className='relative flex justify-center text-sm'>
									<span className='bg-white px-2 text-gray-500'>Ou continuez avec</span>
								</div>
							</div>

							<div className='mt-6 grid grid-cols-3 gap-3'>
								<div>
									<button
										type='button'
										onClick={handleGoogleSignin}
										className='inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50'
									>
										<span className='sr-only'>Se connecter avec Google</span>
										<svg className='h-5 w-5' aria-hidden='true' fill='currentColor' viewBox='0 0 50 50'>
											<path d='M 25.996094 48 C 13.3125 48 2.992188 37.683594 2.992188 25 C 2.992188 12.316406 13.3125 2 25.996094 2 C 31.742188 2 37.242188 4.128906 41.488281 7.996094 L 42.261719 8.703125 L 34.675781 16.289063 L 33.972656 15.6875 C 31.746094 13.78125 28.914063 12.730469 25.996094 12.730469 C 19.230469 12.730469 13.722656 18.234375 13.722656 25 C 13.722656 31.765625 19.230469 37.269531 25.996094 37.269531 C 30.875 37.269531 34.730469 34.777344 36.546875 30.53125 L 24.996094 30.53125 L 24.996094 20.175781 L 47.546875 20.207031 L 47.714844 21 C 48.890625 26.582031 47.949219 34.792969 43.183594 40.667969 C 39.238281 45.53125 33.457031 48 25.996094 48 Z' />
										</svg>
									</button>
								</div>
								<div>
									<button
										type='button'
										onClick={handleGoogleSignin}
										className='inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50'
									>
										<span className='sr-only'>Se connecter avec Facebook</span>
										<svg className='h-5 w-5' aria-hidden='true' fill='currentColor' viewBox='0 0 20 20'>
											<path
												fillRule='evenodd'
												d='M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z'
												clipRule='evenodd'
											/>
										</svg>
									</button>
								</div>

								{/* <div>
									<a
										href='#'
										className='inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50'
									>
										<span className='sr-only'>Sign in with Twitter</span>
										<svg className='h-5 w-5' aria-hidden='true' fill='currentColor' viewBox='0 0 20 20'>
											<path d='M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84' />
										</svg>
									</a>
								</div>

								<div>
									<button
										type='button'
										onClick={handleGithubSignin}
										className='inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50'
									>
										<span className='sr-only'>Sign in with GitHub</span>
										<svg className='h-5 w-5' aria-hidden='true' fill='currentColor' viewBox='0 0 20 20'>
											<path
												fillRule='evenodd'
												d='M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z'
												clipRule='evenodd'
											/>
										</svg>
									</button>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <h1 className='text-center text-3xl font-bold '>Se connecter</h1>
			<div className='m-5 flex flex-col items-center justify-center gap-3'>
				<button
					type='button'
					className='flex items-center gap-3 rounded-lg border  border-gray-50 px-8 py-4'
					onClick={handleGoogleSignin}
				>
					Sign In with Google
					<Image src={'/assets/google.svg'} width='20' height={20} alt=''></Image>
				</button>
				<button
					type='button'
					className='flex items-center gap-3 rounded-lg border border-gray-50 px-8 py-4'
					onClick={handleGithubSignin}
				>
					Sign In with Github
					<Image src={'/assets/github.svg'} width='20' height={20} alt=''></Image>
				</button>
			</div> */}
			{/* {Object.values(providers).map((provider) => (
				<div key={provider.name} className='my-10 text-center'>
					<button onClick={() => signIn(provider.id, { callbackUrl: redirectedTo })}>
						Sign in with {provider.name}
					</button>
				</div>
			))} */}
		</Page>
	);
}

export const getServerSideProps: GetServerSideProps = async (context: GetServerSidePropsContext) => {
	const providers = await getProviders();
	const session = await getSession(context);

	if (session) {
		return {
			redirect: {
				destination: '/admin',
				permanent: false,
			},
			props: {},
		};
	}

	return {
		props: { providers },
	};
};

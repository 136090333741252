type MenuIconProps = {
	menuOpen: boolean;
	handleOpenMenu: () => void;
	className?: string;
};

export default function MenuIcon({ menuOpen, handleOpenMenu, className }: MenuIconProps): JSX.Element {
	const common = 'block border border-white my-2 border-opacity-70 transition duration-[800ms] ease-in-out transform rounded';
	const transition = {
		top: 'translate-y-3  rotate-[45deg]',
		middle: ' translate-x-[-100px] ',
		bottom: '-translate-y-2 rotate-[-45deg]',
	};

	return (
		<button
			type='button'
			onClick={handleOpenMenu}
			className={`${className} onClick={handleOpenMenu}`}
			aria-label={menuOpen ? 'Fermer le menu' : 'Ouvrir le menu'}
		>
			<span className={`${common} w-10 ${menuOpen ? transition.top : ''}`} />
			<span className={`${common} w-7 ${menuOpen ? transition.middle : ''}`} />
			<span className={`${common} w-10 ${menuOpen ? transition.bottom : ''}`} />
		</button>
	);
}

'use client';
import React from 'react';
import { NavLink } from '@/common/components/NavLink';
import { MobileNavProps, NavigationProps } from '@/common/types';

export default function Navigation({ links, isMobileNavOpen, mobileHandler }: MobileNavProps): JSX.Element {
	return (
		<>
			<DesktopNavigation links={links} />
			<MobileNavigation mobileHandler={mobileHandler} isMobileNavOpen={isMobileNavOpen} links={links} />
		</>
	);
}

function DesktopNavigation({ links }: NavigationProps): JSX.Element {
	return (
		<div className='hidden items-center md:flex'>
			<nav className='mx-8 hidden gap-4 md:flex'>
				{links.map((link) => (
					<NavLink key={link.label} href={link.href}>
						{link.label}
					</NavLink>
				))}
			</nav>
		</div>
	);
}
function MobileNavigation({ links, isMobileNavOpen, mobileHandler }: MobileNavProps): JSX.Element {
	const openClassName = isMobileNavOpen
		? 'absolute block h-screen top-20 left-0 w-full isolate z-10 bg-white dark:bg-darkGrey'
		: 'hidden dark:bg-transparent';
	return (
		<div className={`origin-bottom transform transition duration-1000 ease-in-out ${openClassName} md:hidden`}>
			<nav className='flex flex-col items-center justify-center space-y-7 py-10 text-2xl'>
				{links.map((link) => (
					<NavLink key={link.label} href={link.href} handler={mobileHandler}>
						{link.label}
					</NavLink>
				))}
			</nav>
		</div>
	);
}

'use client';
/* eslint-disable react/jsx-props-no-spreading */
import Link from 'next/link';
import { usePathname } from 'next/navigation';

export function NavLink({
	href,
	children,
	handler,
}: {
	href: string;
	children: React.ReactNode;
	handler?: () => void;
}): JSX.Element {
	const pathname = usePathname();
	const isActive = pathname?.split('/')[1] === href.split('/')[1];
	return (
		<Link
			href={href}
			className={
				isActive
					? 'rounded bg-darkGrey px-2 py-1 font-bold text-white dark:bg-white dark:text-darkGrey md:bg-white md:text-black'
					: '-mx-2 rounded py-1 px-2 text-darkGrey hover:bg-gray-800 hover:text-white  focus:bg-gray-800 focus:text-white focus:outline-none dark:text-white dark:hover:text-white md:text-white '
			}
			{...(handler && { onClick: handler })}
		>
			{children}
		</Link>
	);
}

'use client';
import { PlayPauseButton } from '@/player/components';
import { usePlayerContext } from '@/player/context';
import { RADIOSUDEST, NAV_LINKS } from 'src/data';
import { Logo } from '@/common/components/icons';
import MenuIcon from '@/common/components/icons/MenuIcon';
import { useState } from 'react';
import Navigation from './Navigation';
import Link from 'next/link';

export default function Header({ className = '' }: { className?: string }): JSX.Element {
	const [, { setMediaAndPlay, getMediaStatus }] = usePlayerContext();
	const [mobileNavOpen, setMobileNavOpen] = useState(false);

	const handleOpenMenu = () => {
		setMobileNavOpen(!mobileNavOpen);
	};

	const handleMobileNavClose = () => {
		setMobileNavOpen(false);
	};
	return (
		<header>
			<div className={`flex h-20 justify-between bg-gradient-to-r from-[#9C0AA3]  to-[#d98cdd] text-white ${className}`}>
				<MenuIcon handleOpenMenu={handleOpenMenu} menuOpen={mobileNavOpen} className='bg-[#20232A] py-2 px-3 md:hidden' />
				<div className='flex w-full items-center justify-between'>
					<div className='mx-4 flex gap-3 md:mx-8'>
						<Logo />
						<Navigation links={NAV_LINKS} isMobileNavOpen={mobileNavOpen} mobileHandler={handleMobileNavClose} />
					</div>
					<div className='mx-4 flex gap-3 md:mx-8'>
						<PlayPauseButton
							action={() => {
								setMediaAndPlay(RADIOSUDEST);
							}}
							status={getMediaStatus(RADIOSUDEST)}
						/>
					</div>
				</div>
				{/* <div className='flex h-20 items-center justify-center px-2 md:w-[15%] xl:w-[25%]'>
					<PlayPauseButton
						action={() => {
							setMediaAndPlay(RADIOSUDEST);
						}}
						status={getMediaStatus(RADIOSUDEST)}
					/>
				</div> */}
			</div>
			<div className=' bg-gray-800 px-9 py-4 text-white dark:bg-gray-900'>
				<nav className='flex gap-6'>
					{/* <Link href='/grille-des-programmes'>Grille des programmes</Link> */}
					<Link href='/equipe'>L'équipe</Link>
					<Link href='/programmes'>Les programmes</Link>
					{/* <Link href='/grille-des-programmes'>Petites annonces</Link> */}
				</nav>
			</div>
		</header>
	);
}

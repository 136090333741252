import { IPage } from '@/models/page';
import { NextSeo } from 'next-seo';

export default function BasePage({ children, className, seo }: IPage): JSX.Element {
	const pageTitle = seo.slogan ? `${seo.title} — ${seo.slogan}` : `${seo.title} — Radio Sud Est, Martinique`;
	const pageDescription =
		seo.description ||
		`Écoutez en direct Radio Sud Est, la 1ère Radio sur le Kompas en Martinique pour découvrir les meilleures nouveautés de la Caraïbe, et plus encore !`;

	return (
		<div>
			<NextSeo
				title={pageTitle}
				description={pageDescription}
				canonical={seo.canonical}
				noindex={seo.noindex}
				nofollow={seo.nofollow}
				openGraph={{
					title: pageTitle,
					description: pageDescription,
					images: [
						{
							url: seo.image || 'https://assets.radiosudest.com/assets/radiosudest-og_wixuis.jpg',
							width: 800,
							height: 600,
							alt: seo.title || 'Radio Sud Est',
							type: 'image/jpeg',
						},
					],
					type: seo.type || 'website',
					article: {
						authors: seo.authors,
						publishedTime: seo.publishedTime,
						modifiedTime: seo.modifiedTime,
					},
				}}
				twitter={{
					cardType: 'summary_large_image',
				}}
			/>
			<main className={className}>{children}</main>
		</div>
	);
}
